import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";

const NewVersionModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true); // Always show the modal on every reload or fresh visit
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoToNewApp = () => {
    window.location.href = "https://digilab.parleii.com"; // Redirect to the new app
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="new-version-title"
      aria-describedby="new-version-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="new-version-title" variant="h6" component="h2" fontWeight="bold">
          New Web App Available
        </Typography>
        <Typography id="new-version-description" sx={{ mt: 2 }}>
          A new version of the DigiLab web app is available. This version (legacy)
          will stop being updated on 12/10/24 but will remain accessible at digilab-legacy.parleii.com
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleGoToNewApp} sx={{ mr: 2 }}>
            Go to New App
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Stay Here
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewVersionModal;
